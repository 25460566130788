<i18n>
{
  "ru": {
    "title": "Добавление товаров подрядчику"
  },
  "he": {
    "title": "הוספת מוצרים לקבלן"
  }
}
</i18n>

<template>
  <div>
    <h1 class="text-h4 mb-2">{{ $t('title') }}</h1>

    <v-snackbar
      v-model="snackbar.show"
      :timeout="3000"
      :color="snackbar.color"
      centered
    >
      <div class="text-center" v-html="snackbar.text"></div>
    </v-snackbar>

    <v-form v-model="valid">
      <v-row class="mb-5">
        <template v-for="(item, index) in propsInfo">
          <v-col
            cols="6"
            :key="`prop-item-info-${index}`"
          >
            <template v-if="item.type === 'contractors'">
              <SelectContractors
                v-model="item.value"
                :label="item.name"
                :disabled="item.disabled"
                :required="item.required"
                :rules="item.rules"
              ></SelectContractors>
            </template>
          </v-col>
        </template>
      </v-row>

      <h2 class="text-h5 mb-2">
        {{ this.$t('goods') }} ({{ this.$t('selected') }}: {{ selectedItems.length }})

        <v-btn
          text
          color="error"
          @click="selectedItems = []"
        >{{ this.$t('btn.reset') }}</v-btn>
      </h2>

      <v-row class="align-center mb-5">
        <template v-for="(item, index) in filter">
          <v-col
            cols="6"
            :key="`filter-item-${index}`"
          >
            <template v-if="item.type === 'text'">
              <v-text-field
                v-model="item.value"
                :label="item.name"
                color="teal"
                clearable
                :dense="true"
                @change="changeFilter()"
              ></v-text-field>
            </template>

            <template v-if="item.type === 'groupsGoods'">
              <SelectGroupsGoods
                v-model="item.value"
                :label="item.name"
                :dense="true"
                @input="changeFilter()"
              ></SelectGroupsGoods>
            </template>

            <template v-else-if="item.type === 'client'">
              <AutocompleteClient
                v-model="item.value"
                :label="item.name"
                :dense="true"
                @input="changeFilter()"
              ></AutocompleteClient>
            </template>
          </v-col>
        </template>
      </v-row>

      <v-data-table
        :headers="headers"
        :items="items"
        :items-per-page="10"
        class="elevation-1"
        :footer-props="footer"
        :server-items-length="total"
        :page.sync="page"
        :loading="loading"
        item-key="ID"
        show-select
        v-model="selectedItems"
      >
        <template v-slot:item.UF_GROUP="{ item }">
          {{ (find = goodsGroups.find((o) => o.ID === item.UF_GROUP)) ? find.UF_NAME : '-' }}
        </template>

        <template v-slot:item.UF_CLIENT="{ item }">
          {{ item.CLIENT_INFO.UF_NAME }}
        </template>
      </v-data-table>

      <v-row class="mt-5">
        <v-col
          cols="auto"
        >
          <v-btn
            color="teal"
            dark
            :loading="btnLoading"
            @click="save()"
          >{{ $t('btn.save') }}</v-btn>
        </v-col>

        <v-col
          cols="auto"
        >
          <v-btn
            color="grey lighten-5"
            to="/contractors-in-works/"
          >{{ $t('btn.cancel') }}</v-btn>
        </v-col>
      </v-row>
    </v-form>
  </div>
</template>

<script>
import SelectContractors from '@/components/SelectContractors.vue';
import SelectGroupsGoods from '@/components/SelectGroupsGoods.vue';
import AutocompleteClient from '@/components/AutocompleteClient.vue';

export default {
  name: 'ContractorsInWorksAdd',
  components: {
    SelectContractors,
    SelectGroupsGoods,
    AutocompleteClient,
  },
  data: (vm) => ({
    valid: false,
    btnLoading: false,
    snackbar: {},
    props: [
      {
        name: vm.$t('props.UF_CONTRACTOR'),
        code: 'UF_CONTRACTOR',
        type: 'contractors',
        disabled: false,
        required: true,
        rules: [
          (v) => !!v || vm.$t('required'),
        ],
        value: null,
        group: 'info',
      },
    ],
    items: [],
    selectedItems: [],
    filter: [
      {
        name: vm.$t('props.UF_NAME'),
        code: '%=UF_NAME',
        value: null,
        type: 'text',
      },
      {
        name: vm.$t('props.UF_GROUP'),
        code: 'UF_GROUP',
        value: null,
        type: 'groupsGoods',
      },
      {
        name: vm.$t('props.UF_ORDER'),
        code: 'UF_ORDER',
        value: null,
        type: 'text',
      },
      {
        name: vm.$t('props.UF_CLIENT'),
        code: 'UF_CLIENT',
        value: null,
        type: 'client',
      },
    ],
    headers: [
      {
        text: '№',
        value: 'ID',
        sortable: false,
      },
      {
        text: vm.$t('props.UF_NAME'),
        value: 'UF_NAME',
        sortable: false,
      },
      {
        text: vm.$t('props.UF_QUANTITY'),
        value: 'UF_QUANTITY',
        sortable: false,
      },
      {
        text: vm.$t('props.UF_GROUP'),
        value: 'UF_GROUP',
        sortable: false,
      },
      {
        text: vm.$t('props.UF_ORDER'),
        value: 'UF_ORDER',
        sortable: false,
      },
      {
        text: vm.$t('props.UF_CLIENT'),
        value: 'UF_CLIENT',
        sortable: false,
      },
    ],
    footer: {
      'disable-items-per-page': true,
      'items-per-page-options': [50, 100],
    },
    page: 1,
    limit: 50,
    total: 0,
    loading: false,
  }),
  watch: {
    page() {
      this.getOrdersGoods();
    },
  },
  computed: {
    propsInfo() {
      return this.props.filter((item) => item.group === 'info');
    },
    paramsFilter() {
      const params = {};
      const filterProps = this.filter.filter((item) => item.value || item.value === false);

      filterProps.forEach((item) => {
        params[item.code] = item.value;
      });

      params.UF_COMPLETED = '0';
      params['=UF_CONTRACTOR_IN_WORKS'] = '';

      return params;
    },
    goodsGroups() {
      return this.$store.getters.getGroupsGoods;
    },
  },
  methods: {
    changeFilter() {
      this.page = 1;
      this.getOrdersGoods();
    },
    getOrdersGoods() {
      this.loading = true;

      const params = {
        order: {
          UF_ORDER: 'DESC',
        },
        filter: this.paramsFilter,
        select: ['*'],
        limit: this.limit,
        offset: (this.page - 1) * this.limit,
      };

      this.$store.dispatch('getOrdersGoodsNoCmplited', { params }).then((response) => {
        this.items = response.data.data.result;
        this.total = response.data.data.count;

        this.loading = false;
      });
    },
    save() {
      if (this.valid && this.selectedItems.length > 0) {
        this.btnLoading = true;
        const params = new FormData();
        const props = {};

        this.props.forEach((prop) => {
          props[prop.code] = prop.value;
        });

        const ordersGoods = this.selectedItems.map((item) => item.ID);

        params.append('params', JSON.stringify(props));
        params.append('ordersGoods', JSON.stringify(ordersGoods));

        this.$store.dispatch('addContractorsInWorksItem', params).then((response) => {
          const { success } = response.data.data;

          this.btnLoading = false;
          this.snackbar = {
            show: true,
            color: (success) ? 'green darken-3' : 'red darken-3',
            text: response.data.data.message,
          };

          if (success) {
            setTimeout(() => {
              this.$router.push('/contractors-in-works/');
            }, 1500);
          }
        });
      } else {
        this.snackbar = {
          show: true,
          color: 'red darken-3',
          text: this.$t('errorEmptyProps'),
        };
      }
    },
  },
  created() {
    this.getOrdersGoods();
  },
};
</script>
